import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "gKIDS - Training Sessions",
  "featuredImage": "images/gkids-training-sessions.jpg",
  "coverAlt": "#gKids training sessions",
  "description": "Branding package for #gKIDS Training Sessions EP - 6 Images for each track on the project.",
  "date": "2016-09-12",
  "dateModified": "2016-09-12",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Graphic Design", "Music artwork"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`EP cover and 6 images for each version of the single designed for music group #gKIDS as part of their branding package.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skills Used----------`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Software Used------------`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Branding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Photoshop`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphic Design`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Concept creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Brainstorming`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fighting Sleep™`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p><img parentName="p" {...{
        "src": "images/%2523gKIDS%2B-%2B2016%2BEP%2BArtwork.jpg",
        "alt": "#gKIDS Training sessions"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "images/Bench%2BPress.jpg",
        "alt": "#gKIDS - Bench Press"
      }}></img></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1440px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d3728ddd51a3ba0df8ae7388a9b11e6f/04bec/IFLO.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAcCwRQoQR//EABkQAAMAAwAAAAAAAAAAAAAAAAABERAgIf/aAAgBAQABBQLV8xRun//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABsQAQACAgMAAAAAAAAAAAAAAAEAERAxQYGR/9oACAEBAAE/ITU4wawFUlQZ08iK2f/aAAwDAQACAAMAAAAQAAg+/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxCrD//EABsQAQEBAAMBAQAAAAAAAAAAAAERACExQVGR/9oACAEBAAE/EOzLAme9VbuBcpUjxyB5+YQiHB+NNhZOCb//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "#gKIDS - I feel like Obama",
              "title": "#gKIDS - I feel like Obama",
              "src": "/static/d3728ddd51a3ba0df8ae7388a9b11e6f/33266/IFLO.jpg",
              "srcSet": ["/static/d3728ddd51a3ba0df8ae7388a9b11e6f/543cd/IFLO.jpg 360w", "/static/d3728ddd51a3ba0df8ae7388a9b11e6f/20801/IFLO.jpg 720w", "/static/d3728ddd51a3ba0df8ae7388a9b11e6f/33266/IFLO.jpg 1440w", "/static/d3728ddd51a3ba0df8ae7388a9b11e6f/04bec/IFLO.jpg 1600w"],
              "sizes": "(max-width: 1440px) 100vw, 1440px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`#gKIDS - I feel like Obama`}</figcaption>{`
  `}</figure></p>
    <p><img parentName="p" {...{
        "src": "images/Junk%2BFood.jpg",
        "alt": "#gKIDS - Junk Food"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "images/Personal%2BTrainer.jpg",
        "alt": "#gKIDS - Personal Trainer"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "images/Pull%2BUp.jpg",
        "alt": "#gKIDS - Pull Up"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      